@font-face {
  font-family: 'Rubik';
  src: url('https://cdn.massivhaus.de/s/webfonts/rubik/rubik.woff2')
      format('woff2'),
    url('https://cdn.massivhaus.de/s/webfonts/rubik/rubik.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Manrope';
  src: url('https://cdn.massivhaus.de/s/webfonts/manrope/manrope.woff2')
      format('woff2'),
    url('https://cdn.massivhaus.de/s/webfonts/manrope/manrope.woff')
      format('woff');
  font-style: normal;
  font-weight: 400;
}
