:root {
  /* semantic variables */
  --colour-primary-800: #263f46;
  --colour-primary-600: #324a51;
  --colour-primary-400: #52676c;

  --colour-secondary: #f2a71b;

  --colour-tertiary-200: #4c8d9c;
  --colour-tertiary-400: #186c7f;

  --colour-award: #f3ab25;

  --colour-warning: #ffa366;

  --colour-error: #ea4c3b;

  --colour-paper-015: #fffcf7;
  --colour-paper-025: #fdefd6;

  --colour-success-025: #edf2eb;
  --colour-success-050: #cad9c4;
  --colour-success-100: #a7c09e;
  --colour-success-400: #426e33;

  --colour-neutral-025: #f0f1f2;
  --colour-neutral-050: #d4d4d4;
  --colour-neutral-100: #b9b9b9;
  --colour-neutral-200: #848484;
  --colour-neutral-400: #636363;
  --colour-neutral-600: #474747;

  --colour-background-010: #fcfcfb;
  --colour-background-015: #faf9f6;
  --colour-background-025: #f7f6f4;
  --colour-background-050: #d9d5bd;

  --accent-green-200: #1e9575;

  --colour-white: #fff;
  --colour-black: #000;

  /* contextual variables */
  --text-main-on-dark: var(--colour-white);
  --text-main-on-light: var(--colour-primary-600);
  --text-on-secondary: var(--colour-primary-600);
  --text-accent-on-dark: var(--colour-secondary);
  --text-accent-on-light: var(--colour-secondary);
  --border-radius-cta: 2px;
  --border-radius-inputs: 2px;
  --border-radius-card-medium: 2px;
  --border-radius-card-large: 4px;

  /* fonts: START */
  --base-font-size: 16px;
  --base-font-family: 'Manrope', Raleway, Muli, Roboto, Nunito, sans-serif;

  --fancy-font-family: 'Rubik', Arial, Helvetica, Roboto, Montserrat, sans-serif;
  /* fonts: END */

  --border-radius-medium: 1px;
  --border-radius-small: 1px;

  /* elements/CTA: START */
  --cta-primary-hover-box-shadow: 0 6px 12px -8px var(--colour-black),
    0 5px 30px -5px rgba(242, 167, 27, 0.6);
  --cta-primary-hover-box-shadow-dark: 0 4px 16px rgba(0, 0, 0, 0.05);
  --cta-primary-box-shadow: 0 6px 12px -8px var(--colour-black),
    0 5px 30px -5px var(--colour-secondary);
  --cta-primary-box-shadow-with-opacity: 0 6px 18px -15px var(--colour-primary-600),
    0 5px 30px -5px rgba(242, 167, 27, 0.6);
  /* elements/CTA: END */

  --quiz-step-transition-background: rgba(247, 246, 244, 0.9);
  /*background025 colour with 0.9 opacity*/

  /* templates/PageFooter: START */
  --page-footer-background-top: var(--colour-background-010);
  --page-footer-background-mid: var(--colour-background-010);
  --page-footer-background-bottom: var(--colour-background-025);

  --page-footer-text: var(--colour-primary-600);
  --page-footer-text-link: var(--colour-tertiary-400);
  --page-footer-text-link-alt: var(--colour-primary-600);
  /* templates/PageFooter: END */

  --hero-background-overlay: linear-gradient(
    rgba(50, 74, 81, 0.85),
    rgba(50, 74, 81, 0.85)
  );
  --hero-background-overlay-mobile: linear-gradient(
    rgba(50, 74, 81, 0.85) 40%,
    rgba(50, 74, 81, 0.85) 55%
  );
  --hero-background-overlay-bar-tiles: linear-gradient(
    rgba(50, 74, 81, 0.4),
    rgba(50, 74, 81, 0.4)
  );

  --search-result-background: var(--colour-background-010);
  --radio-checked-color: var(--colour-secondary);

  --guidebook-activation-modal-shadow: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(242, 167, 27, 0.6)
  );
}

.mh-logo .fil0 {
  fill: #1a353c;
  fill-rule: nonzero;
}

.mh-logo .fil1 {
  fill: #f2a71b;
  fill-rule: nonzero;
}
